<template>
<div>
 <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>空运出口</el-breadcrumb-item>
    <el-breadcrumb-item>出仓计划</el-breadcrumb-item>
 </el-breadcrumb>
  <!--   搜索Form表单区域   -->
  <el-card>
    <el-form v-model="searchForm" :inline="true">
      <el-form-item label="出仓编号">
        <el-input v-model="searchForm.code" placeholder="请输入出仓编号" clearable style="width: 160px"></el-input>
      </el-form-item>
      <el-form-item label="所属客户">
          <el-select v-model="searchForm.cus_id" placeholder="请选择客户">
            <el-option
              v-for="item in this.customer_list_all"
              :key="item.name"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="出仓状态">
        <el-select v-model="searchForm.ship_status" placeholder="请选择确认状态" clearable style="width: 160px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出仓日期">
        <el-date-picker value-format="timestamp" v-model="searchForm.plan_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 250px">
        </el-date-picker>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card>
    <el-button-group>
      <el-button type="primary" @click="searchShipment" size="small" icon="el-icon-search">查询</el-button>
      <el-button type="primary" @click="addDialogShow"  size="small" icon="el-icon-plus" v-if="isAdminScope || !isRecorderScope">新增</el-button>
      <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="exportExcel" size="small" icon="el-icon-document">导出EXCEL</el-button>
    </el-button-group>
    <el-table :data="tableData" style="width: 100%" stripe :row-style="{height: '40px'}" :cell-style="{padding: '0'}">
      <el-table-column label="业务编号" prop="id" width="80px" fixed="left"></el-table-column>
      <el-table-column label="出仓编号" prop="code" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="车船号" prop="ship_no" width="100px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="集装箱号" prop="container_no" width="150px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="件数" prop="quantity" width="80px"></el-table-column>
      <el-table-column label="重量KG" prop="quality" width="100px" :formatter="toDecimal2"></el-table-column>
      <el-table-column label="体积m^3" prop="cube" width="100px" :formatter="toDecimal3"></el-table-column>
      <el-table-column label="出仓状态" prop="ship_status" width="80px" :formatter="formatYesNO">
<!--        <template slot-scope="scope">-->
<!--           <el-switch disabled-->
<!--            v-model="scope.row.ship_status"-->
<!--            active-color="#409EFF"-->
<!--            inactive-color="#C0CCDA"-->
<!--            :active-value="20"-->
<!--            :inactive-value="10"></el-switch>-->
<!--         </template>-->
      </el-table-column>
      <el-table-column label="备注" prop="remark" width="200px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="所属客户" prop="cus_id" width="200px" :formatter="formatCustomerName" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="出仓时间" prop="plan_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="创建者" prop="creator" width="120px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="更新者" prop="updator" width="120px" :formatter="formatUserName"></el-table-column>
      <el-table-column label="更新时间" prop="update_time" width="165px" :formatter="formatDatetime"></el-table-column>
      <el-table-column label="操作" width="250px" fixed="right" align="left">
       <template slot-scope="scope">
         <el-button-group>
           <el-button
             size="mini"
             type="success"
             icon=""
             @click="exportExcelForBarcode(scope.$index, scope.row)">导出条码</el-button>
          <el-button  v-if="isAdminScope || !isRecorderScope"
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
           <el-button  v-else
            size="mini"
            type="primary"
            icon="el-icon-view"
            @click="handleEdit(scope.$index, scope.row)">浏览</el-button>
          <el-popconfirm
            confirm-button-text='好的'
            cancel-button-text='不用了'
            icon="el-icon-info"
            icon-color="red"
            title="确定要删除吗？"
            @confirm="handleDelete(scope.$index, scope.row)">
              <el-button v-if="isAdminScope || !isRecorderScope"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                slot="reference">删除</el-button>
          </el-popconfirm>
         </el-button-group>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination background style="width: 100%;margin:15px 0px;" class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.page"
      :page-sizes="[3, 6, 10, 20]"
      :page-size="queryInfo.pageSize"
      layout="prev, pager, next, jumper,total, sizes"
      :total="queryInfo.total">
     </el-pagination>
    <!-- 新增出仓计划单对话框 -->
    <el-dialog title="新增出仓计划单" :fullscreen=true :visible.sync="addDialogVisible" @click="addDialogClosed">
      <el-card>
        <div slot="header" class="clearfix">
          <span><i class="el-icon-tickets"></i> 基础信息</span>
        </div>
        <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" :inline="true" label-width="110px">
          <el-form-item label="出仓编号" prop="code">
            <el-input v-model="addForm.code"></el-input>
          </el-form-item>
          <el-form-item label="车船号" prop="ship_no">
            <el-input v-model="addForm.ship_no"></el-input>
          </el-form-item>
          <el-form-item label="装箱单号" prop="container_no">
            <el-input v-model="addForm.container_no"></el-input>
          </el-form-item>
          <el-form-item label="所属客户"  prop="cus_id">
            <el-select v-model="addForm.cus_id" placeholder="请选择所属客户" disabled style="width:220px">
              <el-option
                v-for="item in this.customerList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="件数" prop="quantity">
            <el-input v-model="addForm.quantity" disabled></el-input>
          </el-form-item>
          <el-form-item label="过磅重量KG" prop="quality">
            <el-input v-model="addForm.quality" disabled></el-input>
          </el-form-item>
          <el-form-item label="过磅体积m^3" prop="cube">
            <el-input v-model="addForm.cube" disabled></el-input>
          </el-form-item>
          <el-form-item label="出仓时间" prop="plan_time">
            <el-date-picker v-model="addForm.plan_time" type="datetime" value-format="timestamp"></el-date-picker>
          </el-form-item>
           <el-form-item label="备注" prop="remark">
            <el-input v-model="addForm.remark" type="textarea" style="width: 500px"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card>
        <div slot="header" class="clearfix">
          <span><i class="el-icon-truck"></i> 关联进仓单</span>
        </div>
        <div class="container">
          <el-card style="width: 45%; height: 400px; overflow-y: auto;">
            <el-form v-model="searchGodownForm" :inline="true" style="position: sticky;top: 0px;height: 60px;z-index: 10;background-color: white;">
              <el-form-item label="进仓编号">
                <el-input v-model="searchGodownForm.store_no" placeholder="请输入进仓编号" clearable style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="所属客户" v-show="false">
                  <el-select v-model="searchGodownForm.cus_id" placeholder="请选择客户" style="width: 186px">
                    <el-option
                      v-for="item in this.customer_list_all"
                      :key="item.name"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="searchGodown" size="small" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableGodownsData" @selection-change="handleSelectionChange" style="width: 100%" stripe :row-style="{height: '40px'}" :cell-style="{padding: '0'}">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="业务编号" prop="id" width="80px" fixed="left"></el-table-column>
              <el-table-column label="进仓编号" prop="store_no" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="存放库区" prop="warehouse_area" width="100px" :formatter="formatWHAreaName" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="中文品名" prop="product_name" width="150px" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="件数" prop="quantity" width="80px"></el-table-column>
              <el-table-column label="重量KG" prop="quality" width="100px" :formatter="toDecimal3"></el-table-column>
              <el-table-column label="体积m^3" prop="cube" width="100px" :formatter="toDecimal3"></el-table-column>
            </el-table>
          </el-card>
            <div class="container-1" style="width: 10%">
              <el-button icon="el-icon-d-arrow-right" @click="toRight"></el-button>
              <br><br>
              <el-button icon="el-icon-d-arrow-left" @click="toLeft"></el-button>
            </div>
            <el-card style="width: 45%">
              <el-table :data="tableShipmentData" @selection-change="handleSelectionChangeShipment" style="width: 100%" stripe :row-style="{height: '40px'}" :cell-style="{padding: '0'}">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="业务编号" prop="id" width="80px" fixed="left"></el-table-column>
                <el-table-column label="进仓编号" prop="store_no" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="存放库区" prop="warehouse_area" width="100px" :formatter="formatWHAreaName" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="中文品名" prop="product_name" width="150px" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="件数" prop="quantity" width="80px"></el-table-column>
                <el-table-column label="重量KG" prop="quality" width="100px" :formatter="toDecimal3"></el-table-column>
                <el-table-column label="体积m^3" prop="cube" width="100px" :formatter="toDecimal3"></el-table-column>
              </el-table>
            </el-card>
          </div>
      </el-card>
      <!-- 底部区域 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addShipment">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 编辑出仓计划单对话框 -->
    <el-dialog :title="formTitle" :fullscreen=true :visible.sync="editDialogVisible" @close="editDialogClosed"  style="margin-top:1px;margin-bottom: 1px">
       <!-- 内容主体区 -->
      <el-card>
        <div slot="header" class="clearfix">
          <span><i class="el-icon-tickets"></i> 基础信息</span>
        </div>
        <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" :inline="true" label-width="110px">
          <el-form-item label="出仓编号" prop="code">
            <el-input v-model="editForm.code"></el-input>
          </el-form-item>
          <el-form-item label="车船号" prop="ship_no">
            <el-input v-model="editForm.ship_no"></el-input>
          </el-form-item>
          <el-form-item label="装箱单号" prop="container_no">
            <el-input v-model="editForm.container_no"></el-input>
          </el-form-item>
          <el-form-item label="所属客户"  prop="cus_id">
            <el-select v-model="editForm.cus_id" placeholder="请选择所属客户" disabled style="width:220px">
              <el-option
                v-for="item in this.customerList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="件数" prop="quantity">
            <el-input v-model="editForm.quantity" disabled></el-input>
          </el-form-item>
          <el-form-item label="过磅重量KG" prop="quality">
            <el-input v-model="editForm.quality" disabled></el-input>
          </el-form-item>
          <el-form-item label="过磅体积m^3" prop="cube">
            <el-input v-model="editForm.cube" disabled></el-input>
          </el-form-item>
          <el-form-item label="出仓时间" prop="plan_time">
            <el-date-picker v-model="editForm.plan_time" type="datetime" value-format="timestamp"></el-date-picker>
          </el-form-item>
           <el-form-item label="备注" prop="remark">
            <el-input v-model="editForm.remark" type="textarea" style="width: 500px"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card>
        <div slot="header" class="clearfix">
          <span><i class="el-icon-truck"></i> 关联进仓单</span>
        </div>
        <div class="container">
          <el-card style="width: 45%; height: 400px; overflow-y: auto;">
            <el-form v-model="searchGodownForm" :inline="true" style="position: sticky;top: 0px;height: 60px;z-index: 10;background-color: white;">
              <el-form-item label="进仓编号">
                <el-input v-model="searchGodownForm.store_no" placeholder="请输入进仓编号" clearable style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="所属客户" v-show="false">
                  <el-select v-model="searchGodownForm.cus_id" placeholder="请选择客户" style="width: 186px">
                    <el-option
                      v-for="item in this.customer_list_all"
                      :key="item.name"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="searchGodown" size="small" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableGodownsData" @selection-change="handleSelectionChange" style="width: 100%" stripe :row-style="{height: '40px'}" :cell-style="{padding: '0'}">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="业务编号" prop="id" width="80px" fixed="left"></el-table-column>
              <el-table-column label="进仓编号" prop="store_no" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="存放库区" prop="warehouse_area" width="100px" :formatter="formatWHAreaName" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="中文品名" prop="product_name" width="150px" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="件数" prop="quantity" width="80px"></el-table-column>
              <el-table-column label="重量KG" prop="quality" width="100px" :formatter="toDecimal2"></el-table-column>
              <el-table-column label="体积m^3" prop="cube" width="100px" :formatter="toDecimal3"></el-table-column>
            </el-table>
          </el-card>
            <div class="container-1" style="width: 10%">
              <el-button icon="el-icon-d-arrow-right" @click="toRightEdit"></el-button>
              <br><br>
              <el-button icon="el-icon-d-arrow-left" @click="toLeftEdit"></el-button>
            </div>
            <el-card style="width: 45%">
              <el-table :data="tableShipmentData" @selection-change="handleSelectionChangeShipment" style="width: 100%" stripe :row-style="{height: '40px'}" :cell-style="{padding: '0'}">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="业务编号" prop="id" width="80px" fixed="left"></el-table-column>
                <el-table-column label="进仓编号" prop="store_no" width="200px" fixed="left" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="存放库区" prop="warehouse_area" width="100px" :formatter="formatWHAreaName" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="中文品名" prop="product_name" width="150px" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="件数" prop="quantity" width="80px"></el-table-column>
                <el-table-column label="重量KG" prop="quality" width="100px" :formatter="toDecimal3"></el-table-column>
                <el-table-column label="体积m^3" prop="cube" width="100px" :formatter="toDecimal3"></el-table-column>
              </el-table>
            </el-card>
          </div>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateShipment"  v-if="editForm.ship_status !== 20 && (isAdminScope || !isRecorderScope)">保 存</el-button>
      </div>
    </el-dialog>
  </el-card>
</div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Shipment',
    data () {
      return {
        warehouseAreaList: [],
        fullscreenLoading: false,
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        addDialogVisible: false,
        editDialogVisible: false,
        tableData: [],
        tableGodownsData: [],
        tableShipmentData: [],
        multipleSelection: [],
        multipleSelectionShipment: [],
        options: [{
            value: 0,
            label: '全部'
          }, {
            value: 10,
            label: '未确认'
          }, {
            value: 20,
            label: '已确认'
        }],
        queryInfo: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        queryInfoGodown: {
          query: '',
          page: 1,
          pageSize: 9999,
          total: 0
        },
        queryInfoShipment: {
          query: '',
          page: 1,
          pageSize: 10,
          total: 0
        },
        images: [],
        uploadForm: {
          file: ''
        },
        fileList: [],
        tableImageData: [],
        file: {},
        /** 搜索表单对象 */
        searchForm: {
          code: '',
          ship_status: 0,
          cus_id: 20,
          plan_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
        },
        /** 搜索进仓表单对象 */
        searchGodownForm: {
          store_no: '',
          cus_id: 20,
          bill_status: 20 // 进仓单确认
          // inbound_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
        },
        /** 搜索进仓表单对象 */
        searchShipmentForm: {
          store_no: '',
          cus_id: 20,
          plan_time: [moment().subtract(1, 'months').valueOf(), moment().valueOf()]
        },
        /** 新增入仓单对象 */
        addForm: {
          code: '',
          container_no: '',
          ship_no: '',
          plan_time: moment().valueOf(),
          quantity: 0,
          quality: 0.000,
          cube: 0.000,
          cus_id: 20,
          godowns: [],
          remark: ''
        },
        /** 编辑进仓单数据检查 */
        addFormRules: {
          code: [
            { required: true, message: '请输入出仓编号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在6～20个字符之间', trigger: 'blur' }
          ],
          ship_no: [
            { required: false, message: '请输入车船号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在6～20个字符之间', trigger: 'blur' }
          ],
          container_no: [
            { required: false, message: '请输入集装箱号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在6～20个字符之间', trigger: 'blur' }
          ],
          plan_time: [
            { required: true, message: '请选择出仓时间', trigger: 'blur' }
          ],
          cus_id: [
            { required: false, message: '请选择所属客户', trigger: 'blur' }
          ],
          quantity: [
            { required: false, message: '请输入件数', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '件数必须为正整数', trigger: 'blur' }
          ],
          quality: [
            { required: false, message: '请输入过磅重量', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的过磅重量，保留3位小数', trigger: 'blur' }
          ],
          cube: [
            { required: false, message: '请输入过磅体积', trigger: 'blur' },
            { pattern: /^(0|[1-9]\d{0,9})(\.\d{0,3})?$/, message: '请输入正确的过磅体积，保留3位小数', trigger: 'blur' }
          ],
          remark: [
            { max: 100, message: '长度不能超过100个字符', trigger: 'blur' }
          ]
        },
        /** 编辑入仓单表单对象 */
        editForm: {},
        /** 编辑进仓单数据检查 */
        editFormRules: {},
        customerList: [],
        customer_list_all: [],
        userList: [],
        isAdminScope: window.sessionStorage.getItem('scope') === 'AdminScope',
        isCustomerScope: window.sessionStorage.getItem('scope') === 'CustomerScope',
        /** 判断当前用户是否为录单员 */
        isRecorderScope: window.sessionStorage.getItem('scope') !== 'RecorderScope',
        formTitle: '编辑出仓计划单'
      }
    },
    created: function () {
      this.getWarehouseArea()
      this.getShipmentList(this.queryInfo)
      this.getGodownList(this.queryInfoGodown)
      this.getUserList()
      this.getCustomerList()
      // console.log('isRecordScope: ' + this.isRecorderScope)
      this.formTitle = this.isCustomerScope ? '浏览出仓计划单' : '编辑出仓计划单'
      this.editFormRules = this.addFormRules
    },
    methods: {
      getWarehouseArea () {
        this.$http.get('/dictionary/warehouse_area').then(res => {
          console.log('warehouse_area: ', res.data)
          this.warehouseAreaList = res.data.data.rows
        })
      },
      exportExcel () {
        this.fullscreenLoading = true
        this.$http.get('/shipment/excel', { params: this.searchForm, responseType: 'blob' }).then(
          res => {
            console.log(res)
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
            const fileName = '空运出口出仓计划单.xls'
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)

            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)

            this.fullscreenLoading = false
          },
          err => {
            this.$message.error(err.response.data.msg)
          }
        )
      },
      exportExcelForBarcode (index, row) {
        this.fullscreenLoading = true
        this.$http.get(`/shipment/${row.id}/excel`, { params: this.searchForm, responseType: 'blob' }).then(
          res => {
            console.log(res)
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
            const fileName = `空运出口出仓计划条码-${row.id}.xls`
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)

            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)

            this.fullscreenLoading = false
          },
          err => {
            this.$message.error(err.response.data.msg)
          }
        )
      },
      addDialogShow () {
        this.addDialogVisible = true
        this.tableImageData = []
        this.tableShipmentData = []
        this.getGodownList(this.queryInfoGodown)
      },
      /** 保存出仓计划单 */
      addShipment () {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          const obj = JSON.parse(JSON.stringify(this.addForm)) /** 避免对象浅拷贝 */
          obj.plan_time /= 1000
          this.$http.post('/shipment', obj).then(
            res => {
              this.$message.success({ message: res.data.msg })
              this.addDialogVisible = false
              this.$refs.addFormRef.resetFields()
              this.tableShipmentData = []
              this.getShipmentList(this.queryInfo)
            },
            err => {
              console.log(err)
              this.$message.error({ message: err.response.data.msg })
            }
          )
        })
      },

      /** 更新出仓单 */
      updateShipment: function () {
        this.computerShipment(this.editForm, this.tableShipmentData) /** 确保进仓单信息被刷新 **/
        const obj = JSON.parse(JSON.stringify(this.editForm)) /** 避免对象浅拷贝 */
        obj.plan_time /= 1000
        console.log(obj)
        this.$http.post('/shipment', obj).then(
          res => {
            this.getShipmentList(this.queryInfo)
            this.$message.success({ message: res.data.msg })
            this.editDialogVisible = false
          }
        )
      },
      /** 查询出仓计划列表 */
      searchShipment: function () {
        this.getShipmentList(this.queryInfo)
      },
      /** 查询进仓计划列表 */
      searchGodown: function () {
        this.getGodownList(this.queryInfoGodown)
      },
      /** 获取出仓计划单列表 */
      getShipmentList (queryInfo) {
        const planEndTime = this.searchForm.plan_time
        if (planEndTime) {
          this.searchForm.plan_time[1] = moment(planEndTime[1]).endOf('day').valueOf()
        }
         this.$http.post(`/shipment/pagination/${queryInfo.page}/${queryInfo.pageSize}`, JSON.stringify(this.searchForm)).then(
          res => {
            console.log('this.searchForm :', JSON.stringify(this.searchForm))
            this.queryInfo.total = res.data.data.total
            this.tableData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取进仓单列表 */
      getGodownList (queryInfoGodown) {
        // const planEndTime = this.searchForm.plan_time
        // if (planEndTime) {
        //   this.searchForm.plan_time[1] = moment(planEndTime[1]).endOf('day').valueOf()
        // }
         this.$http.post(`/godown/pagination/${queryInfoGodown.page}/${queryInfoGodown.pageSize}`, JSON.stringify(this.searchGodownForm)).then(
          res => {
            console.log('this.searchGodownForm :', JSON.stringify(this.searchGodownForm))
            this.queryInfoGodown.total = res.data.data.total
            this.tableGodownsData = res.data.data.rows
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 显示编辑出仓计划单的对话框 */
      handleEdit (index, row) {
        this.editDialogVisible = true
        this.$http.get(`/shipment/${row.id}`).then(
          res => {
            const obj = res.data.data
            obj.plan_time *= 1000
            this.editForm = obj
            this.tableShipmentData = obj.godowns
            this.editDialogVisible = true
            this.getShipmentList(this.queryInfo)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 删除指定的出仓单 */
      handleDelete: function (index, row) {
        this.$http.delete(`/shipment/${row.id}`).then(
          res => {
            this.getShipmentList(this.queryInfo)
            this.$message.success({ message: res.data.msg })
          },
          err => {
            // console.log('err: ' + JSON.stringify(err))
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 获取客户列表信息 */
      getCustomerList: function () {
        this.$http.get('/customer/list').then(
          res => {
            this.customerList = res.data.data.rows

            this.customer_list_all = JSON.parse(JSON.stringify(this.customerList))
            this.customer_list_all.unshift({ id: 0, name: '全部' })
            // console.log(this.customerList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      formatWHAreaName: function (row, column, cellValue) {
        let wharea
        for (let i = 0; i < this.warehouseAreaList.length; i++) {
          wharea = this.warehouseAreaList[i]
          if (wharea.value === cellValue) {
            return wharea.name
          }
        }
        return '未知'
      },
      /** 格式化客户名称 */
      formatCustomerName: function (row, column, cellValue) {
        let customer
        for (let i = 0; i < this.customerList.length; i++) {
          customer = this.customerList[i]
          if (customer.id === cellValue) {
            return customer.name
          }
        }
      },
      /** 获取用户列表信息 */
      getUserList: function () {
        this.$http.get('/user/list').then(
          res => {
            this.userList = res.data.data.rows
            // console.log(this.userList)
          },
          err => {
            this.$message.error({ message: err.response.data.msg })
          }
        )
      },
      /** 格式化收费模式 */
      formatChargeMode: function (row, column, cellValue) {
        return cellValue === 1 ? '件数' : cellValue === 2 ? '按质量' : '按体积'
      },
      /** 格式化是否名称 */
      formatYesNO: function (row, column, cellValue) {
        return cellValue === 10 ? '未确认' : '已确认'
      },
      /** 格式化用户名称 */
      formatUserName: function (row, column, cellValue) {
        let user
        for (let i = 0; i < this.userList.length; i++) {
          user = this.userList[i]
          if (user.id === cellValue) {
            return user.nickname
          }
        }
        return window.sessionStorage.getItem('nickname')
      },
      /** 格式化时间戳为标准的时间字符串 */
      formatDatetime: function (row, column, cellValue) {
        return moment(cellValue * 1000).format('YYYY/MM/DD HH:mm:ss')
      },
      /** 改变列表显示记录数触发事件 */
      handleSizeChange (val) {
        this.queryInfo.pageSize = val
        this.getShipmentList(this.queryInfo)
      },
      /** 改变列表显示记录数触发事件 */
      handleSizeChangeGodown (val) {
        this.queryInfoGodown.pageSize = val
        this.getGodownList(this.queryInfoGodown)
      },
      handleSizeChangeShipment (val) {
        this.queryInfoShipment.pageSize = val
        this.getShipmentList(this.queryInfoShipment)
      },
      handleCurrentChange (val) {
        this.queryInfo.page = val
        this.getShipmentList(this.queryInfo)
      },
      handleCurrentChangeGodown (val) {
        this.queryInfoGodown.page = val
        this.getGodownList(this.queryInfoGodown)
      },
      handleCurrentChangeShipment (val) {
        this.queryInfoShipment.page = val
        this.getShipmentList(this.queryInfoShipment)
      },
      /** 监听新增入仓单对话框关闭事件 */
      addDialogClosed () {
        // this.$message.success('success!')
        this.$refs.addFormRef.resetFields()
      },
      /** 监听编辑入仓单对话框关闭事件 */
      editDialogClosed () {
        this.$refs.editFormRef.resetFields()
      },
      /** 保留小数位数，x原始值，y是小数保留位 */
      toDecimal: function (x, y) {
        let f = parseFloat(x)
        if (isNaN(f)) {
            return false
        }
        f = Math.round(x * 10 ** y) / 10 ** y
        let s = f.toString()
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + y) {
            s += '0'
        }
        return s
      },
      toDecimal3: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 3)
      },
      toDecimal2: function (row, column, cellValue) {
        return this.toDecimal(cellValue, 2)
      },
      handleRemove (file) {
        console.log(file)
      },
      handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleDownload (file) {
        console.log(file)
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
        // console.log(this.multipleSelection)
        // console.log(this.tableGodownsData)
        // this.tableShipmentData = this.multipleSelection
      },
      handleSelectionChangeShipment (val) {
        this.multipleSelectionShipment = val
        // this.tableShipmentData = this.multipleSelection
      },
      // handleSelectionChangeShipmentEdit (val) {
      //   this.multipleSelectionShipmentEdit = val
      // },
      subtractArrays (arr1, arr2) {
        return arr1.filter(item => !arr2.includes(item))
      },
      toRight () {
        // console.log(this.tableShipmentData)
        this.tableGodownsData = this.subtractArrays(this.tableGodownsData, this.multipleSelection)
        this.tableShipmentData = this.tableShipmentData.concat(this.multipleSelection)
        // console.log(this.tableGodownsData)
        // console.log(this.tableShipmentData)
        this.computerShipment(this.addForm, this.tableShipmentData)
      },
      toLeft () {
        this.tableGodownsData = this.tableGodownsData.concat(this.multipleSelectionShipment)
        this.tableShipmentData = this.subtractArrays(this.tableShipmentData, this.multipleSelectionShipment)
        this.computerShipment(this.addForm, this.tableShipmentData)
      },
      computerShipment: function (form, data) {
        form.quantity = 0
        form.quality = 0
        form.cube = 0
        form.godowns = []
        for (let i = 0; i < data.length; i++) {
          const row = data[i]
          form.quantity += row.quantity
          form.quality += row.quality
          form.cube += row.cube
          form.godowns.push(row.store_no)
        }
        form.cube = this.toDecimal(form.cube, 2)
        form.quality = this.toDecimal(form.quality, 3)
        form.remark = form.godowns.toString()
      },
      toRightEdit () {
        // console.log(this.tableShipmentData)
        this.tableGodownsData = this.subtractArrays(this.tableGodownsData, this.multipleSelection)
        this.tableShipmentData = this.tableShipmentData.concat(this.multipleSelection)
        // console.log(this.tableGodownsData)
        // console.log(this.tableShipmentData)
        this.computerShipment(this.editForm, this.tableShipmentData)
      },
      toLeftEdit () {
        this.tableGodownsData = this.tableGodownsData.concat(this.multipleSelectionShipment)
        this.tableShipmentData = this.subtractArrays(this.tableShipmentData, this.multipleSelectionShipment)
        this.computerShipment(this.editForm, this.tableShipmentData)
      }
    }
  }
</script>

<style scoped>

.container {
  display: flex; /* 设定为flex布局 */
}

.container-1 {
  display: flex;
  flex-direction: column; /* 子元素垂直排列 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需 */
}
   .el-input .el-date-picker  {
    width: 120px;
  }
</style>
